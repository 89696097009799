export const RouteTitles = {

  // coviar
  '/': 'Proyectos',
  '/proyecto': 'Proyectos',
  '/usuarios': 'Usuarios',
  '/presupuesto-anual': 'Presupuesto Anual',
  '/flujo-mensual': 'Flujo Mensual',

  // -- UEs -----------------------------------------------------------------------------

  // Formulacion
  '/proyecto-UE/:id': 'Formulacion - Datos Generales',
  '/proyecto-UE/:id/descripcion': 'Formulacion - Descripcion',
  '/proyecto-UE/:id/cargar-contrapartes': 'Formulacion - Contraparte',
  '/proyecto-UE/:id/componentes': 'Formulacion - Componentes',
  '/proyecto-UE/:id/:cmp/cargar-actividades': 'Formulacion - Componentes - Actividades',
  '/proyecto-UE/:id/:cmp/indicadores': 'Formulacion - Componentes - Indicadores',

  // Estados
  '/proyecto-UE/:id/estado-proyecto': 'Estado Proyecto'
};
